// import logo from './logo.svg';
import './App.css';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import ChallengeBody from './components/ChallengeBody'
import { Component } from 'react';
import { getParticipants } from './actions/participantActions'
import { getComments } from "./actions/commentsActions";
import {
  BrowserRouter as Router,
  Switch,
  Route
} from 'react-router-dom';
import Participants from './components/Participants';
import ParticipantList from './components/ParticipantList';
import Signup from './components/Signup';
import ReadyToPlay from './components/ReadyToPlay';
import TopNav from './components/TopNav';
import Info from './components/Info';
import BingoComments from './components/BingoComments';

// "deploy" : "aws s3 sync build/ s3://september.cil-bingo.com"

class App extends Component {

  componentDidMount() {
    this.props.getParticipants();
    this.props.getComments();
  }

  render() {
    return (
      <div className="App">
        <Router>
          <TopNav />
          <Switch>
            <Route exact path="/">
              <Participants/>
              {/* <BingoComments/> */}
              {/* <Signup/> */}
            </Route>
            <Route path="/challenge">
              <ChallengeBody />
            </Route>
            <Route path="/readytoplay">
              <ReadyToPlay />
            </Route>
            <Route path="/joingame">
              <Signup />
            </Route>
            <Route path="/info">
              <Info />
            </Route>
          </Switch>
        </Router>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => bindActionCreators({
  getParticipants,
  getComments
}, dispatch)

export default connect(null, mapDispatchToProps)(App)
