import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Component } from 'react';
import ParticipantProgress from './ParticipantProgress';
import { Redirect } from 'react-router';

class Participants extends Component {

    state = {
        newPlayer: false
    }

    joinGame = (e) => {
        e.preventDefault();
        this.setState({ newPlayer: true })
    }

    render() {
        if (this.state.newPlayer) {
            return (<Redirect to="/joingame" />)
        }
        return (
            <div>
                {/* Looking for the August Game?  Click <a href="http://august.cil-bingo.com">here</a>. */}
                <h2 className="white-font">Then And Now Challenge</h2>
                <form onSubmit={this.joinGame}>
                    <button className="form-fields save_updates">Join</button>
                </form>
                
                <div className="display-class">

                    {this.props.participants.sort((val1, val2) => (val1.leaderboardName.toLowerCase() < val2.leaderboardName.toLowerCase()) ? -1 : 1)
                        .map((participant, i) => <ParticipantProgress key={i} participant={participant} participantIndex={i} />)}
                </div>
            </div>

        );
    }
}

const mapStateToProps = (state) => (
    {
        participants: state.participant.participants
    }
)

export default connect(mapStateToProps, null)(Participants);