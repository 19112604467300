import { Component } from "react";
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { updateCard } from '../actions/challengeActions'
import { selectedParticipant } from "../actions/participantActions";
import { Redirect } from 'react-router';

class ParticipantProgress extends Component {

    state = {
        participantSelected : false
    }

    handleParticipantSelected = () => {
        this.props.selectedParticipant(this.props.participantIndex);
        this.setState({ participantSelected : true })
    }

    render() {
        if (this.state.participantSelected){
            return (<Redirect to="/challenge"/>);
        }
        return (
            <div onClick={this.handleParticipantSelected} className={(this.props.participant.percentComplete === 100) ? "ParticipantList ParticipantDone" : "ParticipantList"}>
                <div className="emphasis-text">
                    {this.props.participant.displayLeaderboard}
                </div>
                <div>{this.props.participant.firstName} {this.props.participant.lastName}</div>
                <div>{this.props.participant.game.type}</div>
                <div>
                        Squares: {this.props.participant.percentComplete}%
                </div>
                <div>
                   Miles: {Math.round((this.props.participant.milesCompleted / this.props.participant.mileageGoal) * 100)}%
                </div>
            </div>
        )
    }

}

const mapStateToProps = (state) =>
({
    // card: state.challenge,
    selectedParticipant: state.participant.selectedParticipant
});

const mapDispatchToProps = dispatch => bindActionCreators({
    selectedParticipant
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(ParticipantProgress);