import React from 'react';
import './Ornament.css';

export function OrnaTest(props) {

    return (
        <div >
            {props.children}
        </div>
    )
}