import React, { Component } from "react";
import { OrnaTest } from "./CSSOrnament/OrnamentTest";

class ChallengeClass extends Component {

    state = {
        thenStatus : this.props.square.thenComplete,
        nowStatus : this.props.square.nowComplete,
        classCompleted : this.props.square.thenComplete && this.props.square.nowComplete
    }

    updateDate = (e) => {
        this.props.square.planDate = e.target.value;
        this.props.captureChange(this.props.square)
    }

    updateCompletion = (e) => {
        console.log(e.target.id);
        // this.props.square.complete = !this.state.classCompleted;

        if (e.target.id === 'thenCompleted')
            this.props.square.thenComplete = !this.state.thenStatus;
        else
            this.props.square.nowComplete = !this.state.nowStatus;

            console.log(this.props.square.thenComplete, this.props.square.nowComplete);
        this.setState({ 
            thenStatus : this.props.square.thenComplete,
            nowStatus : this.props.square.nowComplete,
            classCompleted : this.props.square.thenComplete && this.props.square.nowComplete
        })

        this.props.square.complete = this.props.square.thenComplete && this.props.square.nowComplete;
        this.props.captureChange(this.props.square)
    }

    render() {
        return (
            <div className={(this.state.classCompleted) ? "ClassDone" : "TestIt"}>  
                <OrnaTest >

                {/* <label className="emphasis-text" htmlFor="class">{this.props.square.classType}<br/> */}
                {/* <label className="emphasis-text" htmlFor="class">{this.props.square.instructorNum}<br/> */}
                {/* <label className="emphasis-text" htmlFor="class"> */}
                <div className="details">
                <div className="emphasis-text">{this.props.square.instructor}<br/></div>
                {/* </label> */}

                <input type="date" onChange={(event) => this.updateDate(event)} id="class" name="class"
                    defaultValue={this.props.square.planDate} />
                <div>   
                <input type="checkbox"  id="thenCompleted" name="thenCompleted"
                    checked={this.state.thenStatus} onChange={(event) =>this.updateCompletion(event)}/> then
                </div>    
                <div>   
                <input type="checkbox"  id="nowCompleted" name="nowCompleted"
                    checked={this.state.nowStatus} onChange={(event) =>this.updateCompletion(event)}/> now
                </div>   
                </div>
                <div className="instructor">
                <img src={this.props.square.url} alt={this.props.square.instructor}></img>
                </div>
                </OrnaTest>
                {/* checked={(this.props.square.complete) ? true : false}  */}
                {/* // {(event) => this.updateCompletion(event) */}
            </div>
        )
    }
}

export default ChallengeClass;