export const GET_CHALLENGE_CARD = "GET_CHALLENGE_CARD";
export const UPDATE_CARD = "UPDATE_CARD";
export const CLEAR_CARD = "CLEAR_CARD";
export const FETCH_CARD_FAILED = "FETCH_CARD_FAILED";
export const UPDATE_FAILED = "UPDATE_FAILED";


// let targetURL = 'http://localhost:8080'
// Group 1???
// let targetURL = 'https://q6yis939mh.execute-api.us-east-2.amazonaws.com/dev/participant';
// let targetURL = 'https://3gg0ifg8ef.execute-api.us-east-2.amazonaws.com';
// Group 2
let targetURL = 'https://wubcit4utg.execute-api.us-east-2.amazonaws.com';
// let targetURL = 'https://cil-holiday-db.herokuapp.com'


export const getParticipantCard = (selectedParticipant) => {
    return dispatch => {
        fetch(`${targetURL}/card/` + selectedParticipant, 
            {
                method : 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                }
            })
            .then(response => response.json())
            .then(participantCard => dispatch({
                type: GET_CHALLENGE_CARD,
                payload: participantCard.classList
            }))
            .catch(err => dispatch({
                type: FETCH_CARD_FAILED,
                payload: err
            }))
    }
}

export const clearCard = () => {
    return dispatch => {
        dispatch
        ({
            type: CLEAR_CARD,
            payload: []
        })
    }
}


export const updateCard = (updatedClasses, selectedParticipant) => {
    return dispatch => {
        fetch(`${targetURL}/card/` + selectedParticipant,
        {
            method: 'POST',
            body: JSON.stringify(updatedClasses),
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
            }    
        })
        .then(response => response.json())
        .then(response =>
            dispatch({
                type : UPDATE_CARD,
                payload : response.classList
            }))
        .catch(err => dispatch({
            type : UPDATE_FAILED,
            payload: err
        }))
    }
}